import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useQuery } from 'react-query'
import {
    Box,
    Button,
    IconButton,
} from "@mui/material";
import { CarouselProvider, Slider, Slide, DotGroup, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
// import { YouTubeEmbed } from 'react-social-media-embed';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import WelcomeDialog from './WelcomeDialog';
import { useSelector } from 'react-redux';

const possiblePages = [
    'full-register',
    'clever-auth',
    'email-verification',
    'reset-password',
    'login',
    'module',
    'profile',
    'get-started',
    'welcome',
    'learn-more',
    'customer-support',
    'help-check',
    'privacy-policy',
    'success-skills-intro',
    'success-skills-form',
    'success-skills-search',
    'success-skills-list',
    'success-skills-details',
    'module-detail',
    'wordscape',
    'altspace-vr',
    'altspace-vrws',
    'lesson',
    'skills',
    'skill-detail',
    'skill-detail-assess',
    'skill-detail-sims',
    'skill-detail-quiz',
    'quiz-result',
    'rewards',
    'chatbot',
    'video',
    'sticky',
    'sticky-demo',
    'planner'
]

export default function Tutorial({ page }) {

    const isTutorial = useSelector(state => state.user.userData?.isTutorial) || false
    const tutorials = useSelector(state => state.user.userData?.tutorials) || []

    // const getTutorialData = (path) => {
    //     if (page == "askAnything") {
    //         return [{ link: 'https://stickball.s3.amazonaws.com/new-ask-anything.mp4' }];
    //     }
    //     switch (path) {
    //         case 'module':
    //             return [{ link: 'https://stickball.s3.amazonaws.com/new-main.mp4' }];
    //         case 'lesson':
    //             return [{ link: 'https://stickball.s3.amazonaws.com/module.mp4' }];
    //         default:
    //             return [{ link: 'https://stickball.s3.amazonaws.com/module.mp4' }];
    //     }
    // };


    const location = useLocation();
    const { pathname } = location;
    const curentPath = pathname.split('/').pop();
    // const [currentPage] = useState(possiblePages?.filter(page => page.link === curentPath)[0]?.page || 'null')
    const [showTutButton, setShowTutButton] = useState(true)
    const [currentPageData, setCurrentPageData] = useState({})
    const [open, setOpen] = useState(false)
    const [numPages, setNumPages] = useState(null);
    const [showWelcome, setShowWelcome] = useState(false)
    // const tutData = getTutorialData(curentPath);
    const tutData = tutorials


    useEffect(() => {
        const hasVisitedBefore = localStorage.getItem(`hasVisitedMyPage`);
        if (!hasVisitedBefore) {
            setShowWelcome(true)
        }
    }, [])
    if (!showTutButton) return (<></>)
    return (
        <>
            <WelcomeDialog open={showWelcome} setOpen={setShowWelcome} />
            {
                isTutorial &&
                <IconButton
                    sx={{ backgroundColor: 'white', boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.3)', p: .5 }}
                    onClick={() => setOpen(true)}
                >
                    <QuestionMarkIcon color="primary" sx={{ width: '15px', height: '15px' }} />
                </IconButton>
            }
            <Dialog
                fullWidth
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
            >
                <DialogTitle id="alert-dialog-title" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>
                        {/* Tutorial */}
                    </span>
                    <IconButton onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CarouselProvider
                        naturalSlideWidth={"100%"}
                        naturalSlideHeight={"400px"}
                        totalSlides={tutData?.length}
                        visibleSlides={1}
                    >
                        <Slider>
                            {
                                tutData?.map((item, index) => {
                                    return (
                                        <Slide style={{
                                            borderRadius: '15px',
                                            height: "400px"
                                        }} index={index} key={index} >
                                            <Box className="slid-item" sx={{ height: { xs: '300px', md: "400px" } }}>

                                                <video
                                                    controls
                                                    autoPlay
                                                    width='100%'
                                                    height='400px'
                                                    playsInline
                                                    alt="All the devices"
                                                    src={item?.link}
                                                >

                                                </video>
                                            </Box>
                                        </Slide>
                                    )
                                })
                            }
                        </Slider>
                        {/* <div className="slid-item">
                            <video
                                controls
                                autoPlay
                                width='100%'
                                height='400px'
                                playsInline
                                alt="All the devices"
                                src={"https://stickball.s3.amazonaws.com/Take+a+Tour.mp4"}
                            >

                            </video>
                        </div> */}
                        {/* <DotGroup disableActiveDots={true} /> */}
                        {tutData.length > 1 &&
                            <div style={{ width: '100%', marginTop: 20, display: 'flex', justifyContent: 'center' }}>
                                <ButtonBack style={{ border: 'none', backgroundColor: 'transparent' }}>
                                    <IconButton>
                                        <ArrowBackIosRoundedIcon />
                                    </IconButton>
                                </ButtonBack>
                                <ButtonNext style={{ border: 'none', backgroundColor: 'transparent' }}>
                                    <IconButton>
                                        <ArrowForwardIosRoundedIcon />
                                    </IconButton>
                                </ButtonNext>
                            </div>}

                    </CarouselProvider>


                    {
                        // currentPageData?.fileType === "PDF" ?
                        //     <div style={{ width: '100%', height: '600px', display: 'flex', justifyContent: 'center' }}>
                        //         <Document file={process.env.NEXT_PUBLIC_S3_BUCKET_LINK + currentPageData?.fileLink} onLoadSuccess={onDocumentLoadSuccess}>
                        //             {Array.from(
                        //                 new Array(numPages),
                        //                 (el, index) => (
                        //                     <Page
                        //                         key={`page_${index + 1}`}
                        //                         pageNumber={index + 1}
                        //                     />
                        //                 ),
                        //             )}
                        //         </Document>
                        //     </div>
                        //     : currentPageData?.fileType === "Video" ?
                        //         <video width='100%' height="auto" controls autoPlay>
                        //             <source src={process.env.NEXT_PUBLIC_S3_BUCKET_LINK + currentPageData?.fileLink} type="video/mp4" />
                        //         </video>
                        //         :
                        // <img width="80%" src={process.env.NEXT_PUBLIC_S3_BUCKET_LINK + currentPageData?.fileLink || "https://i.pinimg.com/originals/86/8c/ca/868ccae96fe7219f7a0f5305c529c58f.gif"} alt="gif" />
                    }

                </DialogContent>
            </Dialog>
        </>
    )
}
const tutBtnStyle = {
    width: { xs: '20px', md: '30px' },
    height: { xs: '20px', md: '30px' },
    display: { xs: 'none', md: 'flex' },
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.3)',
    borderRadius: '50%',
    position: 'fixed',
    zIndex: 9999,
    left: { lg: 50, xs: 30 },
    bottom: 20,
    backgroundColor: '#fff',
    cursor: 'pointer'

}