import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Box, IconButton, Tooltip, DialogTitle } from '@mui/material';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import Loading from './Loading';

export default function AnalyticsDialog() {
    const [open, setOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    let email = localStorage.getItem("userEmail")
    const completedSkills = useSelector((state) => state.user.completedSkills)
    const user = useSelector((state) => state.user.userData?.user)

    if (!completedSkills?.length) {
        return <></>
    }
    return (
        <>
            <Tooltip title="Analytics" arrow>
                <IconButton
                    color="primary"
                    sx={{
                        position: "fixed",
                        bottom: 275,
                        right: 30,
                        width: "50px",
                        height: "50px",
                        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.3)",
                        backgroundColor: "#fff"
                    }}
                    onClick={() => setOpen(true)}>
                    <AnalyticsIcon />
                </IconButton>
            </Tooltip>
            <Dialog

                fullWidth
                maxWidth="md"
                open={open}
                onClose={() => setOpen(false)}
                PaperProps={{
                    sx: {
                        borderRadius: "32px"
                    }
                }}
            >
                <DialogTitle sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", p: 0 }}>
                    <IconButton sx={{ mt: 1, mr: 1 }} onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "30px"
                    }}>
                        {
                            isLoading &&
                            <Loading />
                        }
                        <iframe
                            onLoad={() => setIsLoading(false)}
                            style={{ width: "100%", maxHeight: "80vh", height: "700px" }}
                            src={`https://analytics.stickball.biz/tallmadge-school/email/${email}/${user?.organizationId}`} frameborder="0"></iframe>
                    </Box>

                </DialogContent>
            </Dialog>
        </>
    );
}